<template>
  <el-table
    ref="tableControl"
    :key="updateTable"
    v-loading="isLoading"
    class="table-control"
    :data="tableData"
    :row-class-name="tableRowClassName"
    style="width: 100%"
    stripe
    @cell-mouse-enter="handleCellMouseEnter"
    @cell-mouse-leave="handleCellMouseLeave"
  >
    <template v-for="item in tableFieldsDesktop">
      <el-table-column
        :key="item.id"
        :width="item.width ? item.width : ''"
        :class-name="`table-control__${item.prop}`"
        :sortable="item.sortKey"
        :align="item.align"
      >
        <template slot="header">
          <TableLabel
            :data="item"
            :sortable="item.sortKey"
            @sortBy="handleSort"
          />
        </template>

        <template slot-scope="scope">
          <slot :data="{ row: scope.row, prop: item.prop }" />
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import TableLabel from '@/UI/table/table-body/components/TableLabel'
export default {
  name: 'SupervisorTableBody',
  components: { TableLabel },
  props: {
    tableData: { type: Array, required: true },
    tableFieldsDesktop: { type: Array, required: true },
    hasIconEdit: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      multipleSelectionList: [],
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      multipleSelectionLocalNames: [],
      hoverRowIds: [],
    }
  },
  computed: {
    updateTable() {
      if (this.isSmallMobile) {
        return 'smallMobile'
      }
      if (this.isMobile) {
        return 'mobile'
      }
      if (this.isTablet) {
        return 'tablet'
      }

      return 'desktop'
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (this.multipleSelectionIds.includes(row.id)) {
        return 'active-row'
      }
      if (this.hoverRowIds.includes(row.culture_id)) {
        return 'hover-row'
      }

      return ''
    },
    handleSort(sortKey) {
      this.$emit('tableSortByKey', sortKey)
    },
    handleCellMouseEnter(row) {
      this.hoverRowIds = []
      this.hoverRowIds.push(row.culture_id)
    },
    handleCellMouseLeave() {
      this.hoverRowIds = []
    },
  },
}
</script>

<style lang="sass" scoped>
@media (max-width: 768px)
.el-table :deep(.el-table__body .el-table__cell .cell)
  font-size: 14px !important
</style>
