var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      key: _vm.updateTable,
      ref: "tableControl",
      staticClass: "table-control",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        "row-class-name": _vm.tableRowClassName,
        stripe: "",
      },
      on: {
        "cell-mouse-enter": _vm.handleCellMouseEnter,
        "cell-mouse-leave": _vm.handleCellMouseLeave,
      },
    },
    [
      _vm._l(_vm.tableFieldsDesktop, function (item) {
        return [
          _c(
            "el-table-column",
            {
              key: item.id,
              attrs: {
                width: item.width ? item.width : "",
                "class-name": `table-control__${item.prop}`,
                sortable: item.sortKey,
                align: item.align,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._t("default", null, {
                          data: { row: scope.row, prop: item.prop },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("TableLabel", {
                    attrs: { data: item, sortable: item.sortKey },
                    on: { sortBy: _vm.handleSort },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }